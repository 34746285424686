<template>
  <div
    id="selectboxComm"
    :class="[
      'selectbox_comm',
      {
        disabled: isDisabled,
        valued: String(value).length > 0
      },
      size == 'small' ? 'selectbox_small' : ''
    ]" 
    :style="{ maxWidth: maxWidth }"
    ref="selectElement">
    <button class="btn_label" @click="$emit('onClickSelectbox')">
      <span class="txt_option">{{ value || placeholder }}</span>
      <IconSvg
        iconName="arrow_fold"
        size="24"
        :iconColor="size == 'small' ? '#232323' : '#000'"/>
    </button>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name: 'SelectboxCustom',
  components: {
    IconSvg
  },
  props: {
    size: {
      type:String,
      default:'default' // default, small
    },
    value: [String, Number, Array],
    placeholder: {
      type: String,
      default: '선택'
    },
    maxWidth: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>
.selectbox_comm{display:inline-block;position:relative;vertical-align:top}
.selectbox_comm .btn_label{display:inline-block;overflow:hidden;position:relative;width:100%;min-height:54px;padding:15px 56px 15px 16px;outline:none;border:0 none;border-radius:4px;font-weight:600;font-size:16px;line-height:24px;background-color:#F3F1F1;color:#999;box-sizing:border-box;vertical-align:top;text-align:left;letter-spacing:-.02em;white-space:nowrap;text-overflow:ellipsis}
/* .selectbox_comm .btn_label .img_option{float:left;width:24px;height:24px;margin:6px 8px 6px 0;border-radius:4px;background-color:$DISABLED_BG;background-repeat:no-repeat;background-size:cover;background-position:center;vertical-align:top}
.selectbox_comm .btn_label .txt_option{display:block;padding:3px 0;line-height:30px} */
.selectbox_comm .btn_label .txt_option{margin-right:16px}
.selectbox_comm .btn_label .icon_arrow_fold{position:absolute;top:50%;right:16px;margin-top:-12px}

/* small */
.selectbox_small .btn_label{min-height:36px;padding:7px 30px 7px 16px;border: 1px solid #BEA9ED;border-radius:100px;font-weight:500;font-size:14px;line-height:22px;background-color:#F0EAFA}
.selectbox_small .btn_label .txt_option{margin-right:0}
.selectbox_comm .btn_label .icon_arrow_fold{right:6px}

/* multi tag
.list_tag{margin-left:-10px;padding:2px 0;white-space:normal}
.list_tag li{display:inline-block;vertical-align:top}
.list_tag li .btn_tag{margin:3px;padding:0 10px;border-radius:14px;border:1px solid $DISABLED_BORDER;line-height:24px;background-color:$DISABLED_BG;color:$ACTIVE_TEXT}
.list_tag li .btn_tag .icon_remove{margin:5px 0 0 6px;} */

/* // active
.selectbox_comm.active .btn_label{border:1px solid $PRIMARY_COLOR}
.selectbox_comm.active .icon_arrow_bottom{transform:scaleY(-1)} */

/* valued */
.selectbox_comm.valued .btn_label{color:#111}

/* // disabled
.selectbox_comm.disabled .btn_label{border-color:#f0f0f0;background-color:$DISABLED_BG;color:$DISABLED_TEXT;cursor:no-drop}
.selectbox_comm.disabled .btn_label .icon_arrow_bottom ::v-deep g{fill:$DISABLED_ICON} */

</style>

<template>
  <div
    :class="[
      'group_radio',
      isImg ? 'group_radio_img' : 'group_radio_un_img'
    ]">
    <RadioBoxItem
      v-for="(item, index) in dataList"
      :key="item.codeId || index"
      :name="name"
      :id="item.codeId || item.id"
      :text="item.codeName || item.text || item.name"
      :item="item"
      :colorSelect="colorSelect"
      :isDisabled="isDisabled || item.isDisabled" 
      :value.sync="valueSync" />
  </div>
</template>

<script>
import RadioBoxItem from './RadioBoxItem'

export default {
  name: 'RadioBox',
  components: {
    RadioBoxItem
  },
  props: {
    dataList: Array,
    name: String,
    value: undefined,
    colorSelect: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder:String,
    isImg: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
  },
}
</script>

<style scoped>
.group_radio{display:flex;;flex-wrap:wrap}

.radio_comm{flex:0 1 33.33%;box-sizing:border-box}
.radio_comm ::v-deep .inner_radio{position:relative}

.radio_comm ::v-deep input[type="radio"]{position:absolute;top:0;bottom:0;left:0;right:0;z-index:10;width:100%;height:100%;border:0 none;opacity:0.01;filter:alpha(opacity=1);cursor:pointer}
.radio_comm ::v-deep label{display:block;text-align:center;box-sizing:border-box}
.radio_comm ::v-deep .txt_radio{display:block}
/* 
.radio_comm ::v-deep label{display:block;line-height:20px}
.radio_comm ::v-deep .txt_radio{font-weight:700;font-size:16px;line-height:24px;color:#666;letter-spacing:-0.02em;word-break:break-all}
.radio_comm ::v-deep .txt_radio .box_color{display:inline-block;width:18px;height:18px;margin-right:4px;border-radius:5px;vertical-align:top} */

.group_radio_un_img{margin:-4px}
.group_radio_un_img .radio_comm{padding:4px}
.group_radio_un_img .radio_comm ::v-deep label{padding:7px 0;border:1px solid #E7E5E5;border-radius:100px}
.group_radio_un_img .radio_comm .txt_radio{font-weight:600;font-size:14px;line-height:22px;color:#999;word-break:break-all}
.group_radio_un_img .radio_comm ::v-deep input:checked + label{border-color:#BEA9ED;background-color:#F0EAFA}
.group_radio_un_img .radio_comm ::v-deep input:checked + label .txt_radio{color:#111}

.group_radio_img{margin:-6px}
.group_radio_img .radio_comm{padding:6px}
.group_radio_img .radio_comm ::v-deep .inner_radio{padding-top:100%}
.group_radio_img .radio_comm ::v-deep label{position:absolute;top:0;bottom:0;left:0;right:0;border-radius:4px;background:#F7F6FA;font-size:0;white-space:nowrap;text-align:center}
.group_radio_img .radio_comm ::v-deep label:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.group_radio_img .radio_comm ::v-deep label .inner_label{display:inline-block;vertical-align:middle;white-space:normal}
.group_radio_img .radio_comm ::v-deep .thumb_radio{width:44px;height:44px;margin:0 auto;background-repeat:no-repeat;background-size:contain}
.group_radio_img .radio_comm ::v-deep .thumb_radio + .txt_radio{margin-top:6px;}
.group_radio_img .radio_comm ::v-deep .txt_radio{font-weight:600;font-size:12px;line-height:16px;color:#111;word-break:break-all}
.group_radio_img .radio_comm ::v-deep input:checked + label{border:2px solid #BEA9ED;background-color:#F0EAFA}


/* cheked */
.radio_comm ::v-deep input:checked + label + .icon_radio{border-color:#BEA9ED;background-color:#BEA9ED}

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"selectElement",class:[
    'selectbox_comm',
    {
      disabled: _vm.isDisabled,
      valued: String(_vm.value).length > 0
    },
    _vm.size == 'small' ? 'selectbox_small' : ''
  ],style:({ maxWidth: _vm.maxWidth }),attrs:{"id":"selectboxComm"}},[_c('button',{staticClass:"btn_label",on:{"click":function($event){return _vm.$emit('onClickSelectbox')}}},[_c('span',{staticClass:"txt_option"},[_vm._v(_vm._s(_vm.value || _vm.placeholder))]),_c('IconSvg',{attrs:{"iconName":"arrow_fold","size":"24","iconColor":_vm.size == 'small' ? '#232323' : '#000'}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }